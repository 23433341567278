<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <div style="color: #999999;font-size: 14px">
      <span style="font-size: 16px" class="el-icon-info"></span> {{ pic_numType }}| 已上传({{tableData.length}})张
    </div>
    <el-table  height="calc(100vh - 240px)" :data="tableData" style="width: 100%;">
<!--      <el-table-column type="index" width="55" label="#" >-->
<!--      </el-table-column>-->
      <el-table-column prop="title" min-width="127" label="名称" >
      </el-table-column>
      <el-table-column  prop="link" min-width="127"  label="链接" >
      </el-table-column>
      <el-table-column prop="link_type" width="100" label="链接类型" >
        <template #default="scope">
         <span v-for="(item,i) in (nowData.modo==1?link_type2:link_type)">
           <span v-if="item.id==scope.row.link_type">{{item.name}}</span>
         </span>
        </template>
      </el-table-column>
      <el-table-column prop="pic_url" width="100" label="图片" >
        <template #default="scope">
          <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
            <el-image
                style="width: 60px; height: 60px"
                :src="scope.row.pic_url"
                :preview-src-list="[scope.row.pic_url]">
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" prop="address" label="操作" width="115">
        <template #header>
          <el-button  @click="add()"  :disabled="tableData.length==pic_num" type="primary" style="margin:10px 0;">新增</el-button>
        </template>
        <template #default="scope">
          <el-button-group>
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
            <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40,50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <el-drawer title="模块内容新增/修改"
        v-model="drawer"
        size="50%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-scrollbar height="calc(100vh - 160px)" style="padding-right: 15px;height:calc(100% - 30px) ">
        <el-form
            size="mini"
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm">
            <el-form-item label="名称" prop="title">
              <el-input  v-model="ruleForm.title"></el-input>
            </el-form-item>
          <el-form-item label="链接" prop="">
              <el-input  v-model="ruleForm.link"></el-input>
            </el-form-item>
            <el-form-item label="链接类型" prop="link_type">
              <el-select style="width: 100%" v-model="ruleForm.link_type" >
                <el-option
                    v-for="item in (nowData.modo==1?link_type2:link_type)"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          <el-form-item  label="图片:" prop="pic_url">
            <el-upload
                style=" width: 100px!important;height: 100px!important"
                class="avatar-uploader"
                :action="UpURL"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :http-request="httpRequest"
                :before-upload="beforeAvatarUpload">
              <img v-if="ruleForm.pic_url" :src="ruleForm.pic_url" class="avatar" />
              <i v-else  class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <p style="color: #999999"><span style="font-size: 16px" class="el-icon-info"> </span> {{ pic_urlType}} </p>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <div style="text-align: right">
        <el-button  type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
        <el-button  type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {getUQIMGurl,getIMG,postCateTree,getbrandselect} from "@/api/common"//图片上传接口
import * as axios from "@/api/homepageApi"//本页面接口
export default {
  name: 'product',
  props:['nowData'],
  data(){
    return{
      innerType:true,//属性添加/修改按钮状态
      rowData:{},
      pic_num:0,//上传数量
      pic_numType:'',//上传数量提示
      pic_urlnum:'',//上传大小
      pic_urlType:'',//上传大小提示
      fileList:[],
      UpURL:'',
      login:false,
      drawer:false,//新增修改
      /*根列表*/
      tableData:[],
      page:1,
      limit:10,
      total:0,
      /*////////////////*/
      keywords:'',
      ruleForm:{
        title:'',
        app_home_id:'',
        link:'',
        link_type:'',
        pic_url:[],
      },
      rules:{
        pic_url: [
          { required: true, message: '请上传图片', trigger: 'blue' },
        ],
        title: [
          { required: true, message: '请填写名称', trigger: 'blue' },
        ],
        link_type: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
        link: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
      },

      link_type:[
        {
          id:0,
          name:'无'
        },
        {
          id:1,
          name:'前台分类'
        },
        {
          id:2,
          name:'产品'
        },
        {
          id:3,
          name:'优惠券列表'
        },
        {
          id:4,
          name:'优惠券'
        },
        {
          id:5,
          name:'自定义链接'
        },
      ],
      link_type2:[
        {
          id:0,
          name:'无'
        },
        {
          id:1,
          name:'文章类别'
        },
        {
          id:2,
          name:'意见反馈'
        },
        {
          id:3,
          name:'自定义链接'
        },
      ],

      /**/
    }
  },
  created() {
    console.log(this.nowData.modo)
    this.onTable()
    this.keyupSubmit()
    this.urlType()
  },

  methods:{
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    /*
    *
    * 模块类型。0：轮播图（最多5张，图片尺寸685x270），
    * 1：导航栏（个数不限，图标尺寸89x89），
    * 2：左一右二（必需三张，左图片尺寸313x358，又二363x173），
    * 3：五列（单行五列，必须5张，图片尺寸118x132），
    * 4：单行（单行单列，图片尺寸684x143），
    * 5：三列（单行三列，必须三张，图片尺寸214x223）。所有的数据必须存在对应item条数
    * */
    urlType(){
      if(this.nowData.type==0){
        this.pic_num=5//上传数量
        this.pic_numType='轮播图（最多5张，图片尺寸685x270）'//上传数量提示
        this.pic_urlnum='685x270'//上传大小
        this.pic_urlType='图片尺寸685x270'//上传大小提示
      }else if(this.nowData.type==1){
        this.pic_num=500//上传数量
        this.pic_numType='导航栏（个数不限，图标尺寸89x89）'//上传数量提示
        this.pic_urlnum='89x89'//上传大小
        this.pic_urlType='图标尺寸89x89'//上传大小提示
      }else if(this.nowData.type==2){
        this.pic_num=3//上传数量
        this.pic_numType='左一右二（必需三张，左图片尺寸313x358，右二363x173）'//上传数量提示
        this.pic_urlnum='313x358,'//上传大小
        this.pic_urlType='第一张左图片尺寸313x358，右二363x173'//上传大小提示
      }else if(this.nowData.type==3){
        this.pic_num=5//上传数量
        this.pic_numType='五列（单行五列，必须5张，图片尺寸118x132）'//上传数量提示
        this.pic_urlnum='118x132'//上传大小
        this.pic_urlType='图片尺寸118x132'//上传大小提示
      }else if(this.nowData.type==4){
        this.pic_num=1//上传数量
        this.pic_numType='单行（单行单列，图片尺寸684x143）'//上传数量提示
        this.pic_urlnum='684x143'//上传大小
        this.pic_urlType='图片尺寸684x143'//上传大小提示
      }else if(this.nowData.type==5){
        this.pic_num=3//上传数量
        this.pic_numType='三列（单行三列，必须三张，图片尺寸214x223）'//上传数量提示
        this.pic_urlnum='214x223'//上传大小
        this.pic_urlType='图片尺寸214x223'//上传大小提示
      }
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
        app_home_id:this.nowData.app_home_id,
      }
      axios.getfrontData(form).then((res)=>{
        // console.log(res)
        this.tableData=res.data.data
        // console.log('this.tableData',this.tableData)
        this.total=res.data.total
      }).catch((err)=>{

      })
    },
    postcategory(){
      this.$refs.cascaderHandle.dropDownVisible = false
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
     this.ruleForm={
       title:'',
       link:'',
       link_type:'',
       pic_url:'',
       app_home_id:this.nowData.app_home_id,
     }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.app_home_id=row.app_home_id
      this.ruleForm.title=row.title
      this.ruleForm.link=row.link
      this.ruleForm.link_type=row.link_type
      this.ruleForm.pic_url=row.pic_url
      this.drawer=true
      // console.log('当前商品数据',row)
      // axios.getfrontData({id:row.id}).then((res)=>{
      //   if(res.code==200){
      //     this.ruleForm.app_home_id=this.nowData.app_home_id
      //     this.ruleForm.title=this.nowData.title
      //     this.ruleForm.link=this.nowData.link
      //     this.ruleForm.link_type=this.nowData.link_type
      //     if(!res.data.pic_url||res.data.pic_url.length<1){
      //       this.ruleForm.pic_url=[]
      //     }else {
      //       this.ruleForm.pic_url=res.data.pic_url
      //     }
      //     this.drawer=true
      //   }else {
      //     this.$message.error(res.msg)
      //   }
      // }).catch((err)=>{
      //   this.$message.error('服务器问题！请联系管理员！！')
      // })

    },
    /*列表新增修改*/
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postaddfront(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                  that.login=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditfront(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }

        } else {
          this.login=false
          // console.log('error submit!!')
          return false
        }
      })
    },
    // delePic(val,i){
    //   // console.log(val,i)
    //   if(val.id){
    //     axios.postdelePic({id:val.id}).then((res)=>{
    //       this.ruleForm.pic_url.splice(i,1)
    //     }).catch((err)=>{
    //
    //     })
    //   }else {
    //     this.ruleForm.pic_url.splice(i,1)
    //   }
    // },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelfront({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },
    handleAvatarSuccess(res, file) {
      this.logo = URL.createObjectURL(file.raw)
    },
    //LOGO上传图片方法
    httpRequest(options) {
      this.login=true
      // console.log('options',options.file,options.file.type)
      return new Promise((resolve, reject) => {
        let src=options.file.type
        getUQIMGurl({bucket:'product',ext:'.'+src.substr(6)}).then((res)=>{
          // console.log('获取URL',res)
          if(res.code==0){
            this.UpURL=res.data.upload_url
            let preview_url=res.data.preview_url
            // console.log(this.UpURL)
            getIMG(options.file,this.UpURL,options.file.type).then((res)=>{
              this.ruleForm.pic_url=preview_url
              resolve(res)
              this.login=false
            }).catch((err)=>{
              reject(err)
              this.login=false
            })

          }else {
            this.$message.error('获取上传地址失败！！')
            this.login=false
          }
        })

      })
    },
    //
    //缩略多图上传图片方法
    httparrRequest(options) {
      this.login=true
      let url=''
      // console.log('options',options.file,options.file.type)
      return new Promise((resolve, reject) => {
        let src=options.file.type
        getUQIMGurl({bucket:'product',ext:'.'+src.substr(6)}).then((res)=>{
          // console.log('获取URL',res)
          if(res.code==0){
            this.UpURL=res.data.upload_url
            url=res.data.preview_url
            // console.log(this.UpURL)
            getIMG(options.file,this.UpURL,options.file.type).then((res)=>{
              resolve(res)
              this.ruleForm.pic_url.push({url:url})
              this.login=false
            }).catch((err)=>{
              reject(err)
              this.login=false
            })

          }else {
            this.$message.error('获取上传地址失败！！')
            this.login=false
          }
        })

      })
    },

    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'||
          file.type === "image/png" ||
          file.type === "image/jpg"||
          file.type === "image/gif";
      // const isLt2M = file.size / 1024 / 1024 < 4
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG、JPEG、PNG、 格式!')
      }
      // if (!isLt2M) {
      // this.$message.error('上传头像图片大小不能超过 4MB!')
      // }
      return isJPG
      // && isLt2M
    },
  }
}
</script>
<style lang="less" >
.tab{
  margin: 5px 0 ;
  padding: 10px 20px;
  border: 1px solid #e8e8e8;
}
.avatar-uploader .el-upload {
  width: 98px!important;
  height: 98px!important;
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{
  width: 98px!important;
  height: 98px!important;
}
.el-checkbox{
  height: 32px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 98px!important;
  height: 98px!important;
  line-height: 98px;
  text-align: center;
}
.avatar {
  width: 98px!important;
  height: 98px!important;
  display: block;
}
.el-table .el-table__cell{
  padding: 0;
}
.suo .el-card__body{
  padding: 0!important;
}
.meng:hover .meng_1{
  display: block!important;
}
</style>
//src/api/recomapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
//获取推荐商品列表接口
export function gethomeData(obj,url) {
    return request({
        url: '/api/admin/apphome?w_type=all',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增推荐商品接口
export function postaddhome(obj,url) {
    return request({
        url: '/api/admin/apphome/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑推荐商品接口
export function postedithome(obj,url) {
    return request({
        url: '/api/admin/apphome/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除推荐商品接口
export function postdelhome(obj,url) {
    return request({
        url: '/api/admin/apphome/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

// 用户首页模块

//获取产品绑定列表接口
export function getfrontData(obj,url) {
    return request({
        url: '/api/admin/apphomeitem?w_type=all',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增产品绑定接口
export function postaddfront(obj,url) {
    return request({
        url: '/api/admin/apphomeitem/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑产品绑定接口
export function posteditfront(obj,url) {
    return request({
        url: '/api/admin/apphomeitem/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除产品绑定接口
export function postdelfront(obj,url) {
    return request({
        url: '/api/admin/apphomeitem/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

/*师傅首页模块*/

//获取推荐商品列表接口
export function getmasterhomeData(obj) {
    return request({
        url: '/api/admin/wapphome?w_type=all',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增推荐商品接口
export function postaddmasterhome(obj,url) {
    return request({
        url: '/api/admin/wapphome/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑推荐商品接口
export function posteditmasterhome(obj,url) {
    return request({
        url: '/api/admin/wapphome/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除推荐商品接口
export function postdelmasterhome(obj,url) {
    return request({
        url: '/api/admin/pphome/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
